var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"accent white--text darken-2"},[_vm._v("رسالة جديدة")]),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('div',[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"600px","flat":""}},[_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.noteclasses,"disabled":_vm.students.length != 0 || _vm.toAll,"multiple":"","chips":"","clearable":"","hide-details":"","hide-selected":"","cache-items":"","item-text":"name","item-value":"id","prepend-icon":"mdi-account-circle","append-icon":"","placeholder":"الشعب","return-object":"","solo":""},model:{value:(_vm.classes),callback:function ($$v) {_vm.classes=$$v},expression:"classes"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.notestudents,"loading":_vm.loadingStudents,"disabled":_vm.classes.length != 0 || _vm.toAll,"search-input":_vm.search,"aria-required":"","chips":"","multiple":"","clearable":"","hide-details":"","hide-selected":"","cache-items":"","item-text":"name","item-value":"id","label":"الطلاب","prepend-icon":"mdi-account-circle","append-icon":"","placeholder":"بحث عن الطلاب","return-object":"","solo":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" البحث عن "),_c('strong',[_vm._v("الطلاب")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-circle")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}}),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}]),model:{value:(_vm.students),callback:function ($$v) {_vm.students=$$v},expression:"students"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"ma-2",attrs:{"label":"ارسال للكل"},model:{value:(_vm.toAll),callback:function ($$v) {_vm.toAll=$$v},expression:"toAll"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"name":"input-7-1","outlined":"","label":"الرسالة","rules":_vm.noteRules,"auto-grow":"","prepend-icon":"mdi-comment","required":""},model:{value:(_vm.note.note),callback:function ($$v) {_vm.$set(_vm.note, "note", $$v)},expression:"note.note"}})],1)],1)],1)],1)],1)],1)],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"color":"success","loading":_vm.isClick},on:{"click":_vm.save}},[_vm._v("حفظ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("الغاء")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }