<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card>
      <v-card-title class="accent white--text darken-2"
        >رسالة جديدة</v-card-title
      >
      <v-container>
        <v-row justify="center">
          <div>
            <v-card width="600px" flat class="mx-auto">
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-container>
                    <v-row class="mb-2">
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="classes"
                          :items="noteclasses"
                          :disabled="students.length != 0 || toAll"
                          multiple
                          chips
                          clearable
                          hide-details
                          hide-selected
                          cache-items
                          item-text="name"
                          item-value="id"
                          prepend-icon="mdi-account-circle"
                          append-icon
                          placeholder="الشعب"
                          return-object
                          solo
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row class="mb-2">
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="students"
                          :items="notestudents"
                          :loading="loadingStudents"
                          :disabled="classes.length != 0 || toAll"
                          :search-input.sync="search"
                          aria-required
                          chips
                          multiple
                          clearable
                          hide-details
                          hide-selected
                          cache-items
                          item-text="name"
                          item-value="id"
                          label="الطلاب"
                          prepend-icon="mdi-account-circle"
                          append-icon
                          placeholder="بحث عن الطلاب"
                          return-object
                          solo
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title>
                                البحث عن
                                <strong>الطلاب</strong>
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template
                            v-slot:selection="{ attr, on, item, selected }"
                          >
                            <v-chip
                              v-bind="attr"
                              :input-value="selected"
                              color="blue-grey"
                              class="white--text"
                              v-on="on"
                            >
                              <v-icon left>mdi-account-circle</v-icon>
                              <span v-text="item.name"></span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar
                              color="indigo"
                              class="headline font-weight-light white--text"
                            ></v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon>mdi-account-circle</v-icon>
                            </v-list-item-action>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="mb-2">
                      <v-col cols="12">
                        <v-switch
                          v-model="toAll"
                          class="ma-2"
                          label="ارسال للكل"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row class="mb-2">
                      <v-col cols="12">
                        <v-textarea
                          name="input-7-1"
                          outlined
                          label="الرسالة"
                          :rules="noteRules"
                          auto-grow
                          v-model="note.note"
                          prepend-icon="mdi-comment"
                          required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="save"
          :loading="isClick"
          >حفظ</v-btn
        >
        <v-btn text @click="dialog = false">الغاء</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//new
import { APIService } from "../../services/apiServices";
import debounce from "debounce";
const apiService = new APIService();
export default {
  props: {
    show: Boolean,
  },
  data: () => ({
    valid: true,
    toAll: false,

    isClick: false,
    dialog: false,
    note: {
      note: "",
      sentTo: 0,
      classes: [],
      students: [],
    },

    noteRules: [
      (v) => !!v || "يجب ادخال نص الرسالة",
      (v) => (v && v.length <= 255) || "يجب أن يكون الاسم أقل من 255 حرف",
    ],

    classes: [],
    students: [],
    name: "",

    noteclasses: [],
    notestudents: [],

    search: "",

    loadingStudents: false,
  }),
  created() {
    this.getClasses();
    //this.getStudents();
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.isClick = true;

        if (this.classes.length != 0) {
          this.note.sentTo = 1;
          this.classes.map((c) => this.note.classes.push(c.id));
        }
        if (this.students.length != 0) {
          this.note.sentTo = 2;
          this.students.map((c) => this.note.students.push(c.id));
        }
        if (this.toAll) this.note.sentTo = 0;
        setTimeout(() => {
          apiService
            .addNote(this.note)
            .then((response) => {
              if (response != "") {
                this.note = {
                  note: "",
                  sentTo: 0,
                  classes: [],
                  students: [],
                };
                this.students = [];
                this.classes = [];
                this.dialog = false;
                this.$emit("noteAdded");
              } else {
                this.isClick = false;
              }
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            })
            .finally(() => {
              this.isClick = false;
            });
        }, 3000);

        //this.dialog = false;
      }
    },

    makeSearchStudent: async (value, self) => {
      // Handle empty value
      if (!value) {
        this.notestudents = [];
        // this.person = "";
      }

      if (self.loadingStudents) {
        return;
      }

      await apiService
        .searchStudents(0, value)
        .then((response) => {
          self.notestudents = response.students;
        })
        .catch((error) => {
          self.error = "Unknown Error. Please check details and try again.";
          alert(error);
          self.failed();
        })
        .finally(() => (self.loadingLearner = false));
    },
    getClasses() {
      apiService
        .getClasses()
        .then((response) => {
          this.noteclasses = response.classes;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
  },

  watch: {
    show(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("update:show", val);
      const that = this;
      setTimeout(() => {
        that.$refs.form.resetValidation();
      }, 300);
    },
    classes() {
      console.log(this.classes);
      if (this.classes != null) {
        // this.students = null;
        this.toAll = false;
      }
    },

    search(value) {
      if (!value) {
        return;
      }

      debounce(this.makeSearchStudent, 200)(value, this);
    },
  },
};
</script>
