<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="allNotes"
      sort-by="calories"
      class="elevation-1"
      :loading="isGettingData"
      loading-text="يتم جلب البيانات ...الرجاء الانتظار"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="d-none d-sm-flex"
            >الملاحظات العامة</v-toolbar-title
          >
          <v-divider class="mx-4 d-none d-sm-flex" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="d-none d-sm-flex"
            @click="showAddNewNote = true"
            bottom
            color="blue darken-4"
            dark
            fab
            fixed
            left
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title class="accent white--text darken-2"
                >تعديل الرسالة</v-card-title
              >
              <v-container>
                <v-row>
                  <div>
                    <v-card width="600px" flat class="mx-auto">
                      <v-card-text>
                        <v-form ref="form">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-textarea
                                name="input-7-1"
                                outlined
                                label="الرسالة"
                                auto-grow
                                v-model="editedItem.note"
                                required
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />

                <v-btn
                  color="success"
                  class="mr-4"
                  @click="save"
                  :loading="isClick"
                  >حفظ</v-btn
                >
                <v-btn text @click="dialog = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn rounded color="primary" dark @click="editNote(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn rounded class="mr-2" color="green" @click="sendNote(item)">
          <v-icon>mdi-near-me</v-icon>
        </v-btn>
        <v-btn rounded class="mr-2" color="error" @click="deleteNote(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data>لا يوجد أي رسالة محددة مسبقاً</template>
    </v-data-table>
    <AddGeneralNote :show.sync="showAddNewNote" @noteAdded="refreshData()" />
  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";
import AddGeneralNote from "../generalNotes/AddGeneralNote.vue";
const apiService = new APIService();
export default {
  components: { AddGeneralNote },
  data: () => ({
    dialog: false,
    isClick: false,
    //searchKey: "",
    showAddNewNote: false,
    headers: [
      {
        text: "الرسالة",
        align: "start",
        value: "note",
      },

      // {
      //   text: "تم ارسالها ",
      //   align: "start",
      //   value: "sentTo",
      // },
      {
        text: "المرسل له",
        align: "start",
        value: "sentToNames",
      },
      // {
      //   text: "الشعب",
      //   align: "start",
      //   value: "classes",
      // },
      {
        text: "تاريخ الارسال",
        align: "start",
        value: "sentDate",
      },

      { text: "العمليات", value: "action", sortable: false },
    ],
    allNotes: [],
    editedIndex: -1,
    editedItem: {
      note: "",
    },
    defaultItem: {
      note: "",
    },
  }),
  created() {
    this.getNotes();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "رسالة جديدة" : "تعديل";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    getNotes() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getGeneralNotes()
        .then((response) => {
          that.isGettingData = false;
          this.allNotes = response.notes;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },

    editNote(item) {
      this.editedIndex = this.allNotes.indexOf(item);
      //this.editedItem. = Object.assign({}, item);
      this.editedItem.id = item.id;
      this.editedItem.note = item.note;
      this.dialog = true;
    },

    deleteNote(item) {
      var noteObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد حذف الرسالة")) {
        setTimeout(() => {
          apiService
            .deleteNote(noteObject)
            .then(() => {
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },

    sendNote(item) {
      var noteObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد ارسال الرسالة")) {
        setTimeout(() => {
          apiService
            .sendNote(noteObject)
            .then(() => {
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
    refreshData() {
      this.getNotes();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        this.isClick = true;
        setTimeout(() => {
          apiService
            .updateNote(this.editedItem)
            .then(() => {
              this.isClick = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              this.close();
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
        // } else {
        //   this.desserts.push(this.editedItem);
        // }
      }
    },
  },
};
</script>
